import { ADDING,EDIT,EDITED,EDITING, ADD_PACKAGE, DELETE_PACKAGE, EVALUATE_ORDER, ADD_ORDER, SHOW_CONFIRMATION, CANCEL_ORDER, ADDED_ORDER, ADD_USED, EVALUATE_QUOTA,ADDED_USED, OPEN_DIALOG, CLOSE_DIALOG, ADD_ADDON, DELETE_ADDON, PREPARE_CHECKIN } from './../actions/customerAction'
import { v4 as uuidv4 } from 'uuid';
const initialRecordUse = {
    useDetails : {},
    date_time : null,
    quota_use :0,
    actual_units: 0,
    status:'',
    groupId:'',
    packageId:'',
    calculateResult:undefined
}

const initialState = {
    status : 'EDIT',
    isForceReload: false,
    label : 'แก้ไขข้อมูลลูกค้า',
    disabledForm : true,
    preloadedValues : 'null',
    newOrder : {
        price : 0,
        packages : [],
        status :'',
        addons : []
    },
    recordUsed : initialRecordUse,
    packagesMaster : null,
    addonsMaster : null,
    customer : null,
    address : null,
    cancelOrderDetails: {
        showConfirmation: false,
        confirmed: false,
        orderId: '',
        orderDetail: ''
    },
    isOpen : false,
    btnRefEnable : true
}

const addUsed = (state) =>{
    let recordUsed = state.recordUsed;
    return recordUsed
}  
const addPackage = (state) =>{
    let newOrder = state.newOrder;
    const guid = uuidv4()
    newOrder.packages.push({id:guid,package:"",quantity:1,quota:0})
    return newOrder
}

const addAddon = (state) =>{
    let newOrder = state.newOrder;
    const guid = uuidv4()
    if(!newOrder.addons){
        newOrder.addons = []
    }
    newOrder.addons.push({id:guid,addon:"",quantity:1,quota:0})
    return newOrder
} 
const evaluateOrder = (state, data) =>{
    let newOrder = state.newOrder;
    newOrder.packages = data.packages
    newOrder.addons = data.addons
    if(newOrder.packages!=null) {
        const packageItems = newOrder.packages;
        packageItems.map((_package,index)=>{
            newOrder.packages[index].quota = calculateQuota(newOrder.packages[index], state.packagesMaster);
        })
    }
    
    newOrder.price = calculatePrice(newOrder.packages, state.packagesMaster, newOrder.addons, state.addonsMaster)

    return newOrder 
}  


const evaluateQuota = (state, data, _package) =>{
    
    let recordUsed = state.recordUsed;
    recordUsed.status = data.status
    const calUse = getUseQuota(data,state)
    const calUnit = getUseUnit(data,state)
    let sumUsed = 0;
    if(_package.quota_used){
        Object.entries(_package.quota_used).map((item)=>{
            sumUsed += item[1].quota_used
        })
    }
    
    if(_package.quota >= (calUse + sumUsed)){
        recordUsed.useDetails = data.useDetails
        recordUsed.quota_use = calUse
        recordUsed.actual_units = calUnit
        recordUsed.calculateResult = undefined
    }else{
        recordUsed.calculateResult = 'ใช้เกินโควต้า'
    }
    return recordUsed
}  

const getUseQuota = (data, state) => {
    let packageGroup = state.packagesMaster.find(x=>x.sk === state.recordUsed.groupId)
    const quotaDetails = packageGroup.quota_counting ? Object.entries(packageGroup.quota_counting) : [];
    let countUsed = 0;
    quotaDetails.forEach(element => {
      
      if(typeof data.useDetails[element[0]] != 'undefined' ){
        countUsed +=  (element[1]  * data.useDetails[element[0]])
      }
      
    });
    return countUsed;

}

const getUseUnit = (data, state) => {
    let packageGroup = state.packagesMaster.find(x=>x.sk === state.recordUsed.groupId)
    const quotaDetails = packageGroup.quota_counting ? Object.entries(packageGroup.quota_counting) : [];
    let countUnit = 0;
    quotaDetails.forEach(element => {
      
      if(typeof data.useDetails[element[0]] != 'undefined' ){
        countUnit +=  (1  * data.useDetails[element[0]])
      }
      
    });
    return countUnit;

}

const calculatePrice = (packages, packagesMaster, addons, addonsMaster) => {
    let price = 0;
    if(packages!= null && typeof packages != 'undefined'){
        const packageItems = packages;
        packageItems.map((_package) => {
            const selectedPackage = packagesMaster.find(x=>x.sk === _package.package)
            if(selectedPackage != null) {
                price += selectedPackage.price * _package.quantity;
            }
        });
    
    }

    if(addons!= null && typeof addons != 'undefined'){
        const items = addons;
        items.map((addon) => {
            const selectedAddon = addonsMaster.find(x=>x.id === addon.addon)
            if(selectedAddon != null) {
                price += selectedAddon.price;
            }
        });
    
    }
    return price
    
}
const calculateQuota = (_package, packagesMaster) => {
    let quota = 0
    const selectedPackage = packagesMaster.find(x=>x.sk === _package.package)
    
    if(selectedPackage != null) {
        quota += selectedPackage.quota * _package.quantity;
    }
    return quota
    
}

const deletePackage = (state, id) =>{
    let newOrder = state.newOrder;
    const delItem = newOrder.packages.find(x=>x.id===id);
    const delIndex = newOrder.packages.indexOf(delItem)
    delete newOrder.packages[delIndex];
    newOrder.packages = newOrder.packages.filter(function (el) {
        return typeof el != 'undefined';
      });
    newOrder.price = calculatePrice(newOrder.packages, state.packagesMaster, newOrder.addons, state.addonsMaster)
    return newOrder 
}  

const deleteAddon = (state, id) =>{
    let newOrder = state.newOrder;
    const delItem = newOrder.addons.find(x=>x.id===id);
    const delIndex = newOrder.addons.indexOf(delItem)
    delete newOrder.addons[delIndex];
    newOrder.addons = newOrder.addons.filter(function (el) {
        return typeof el != 'undefined';
      });
    newOrder.price = calculatePrice(newOrder.addons, state.addonsMaster, newOrder.addons, state.addonsMaster)
    return newOrder 
}  
const setShowConfirmation = (state, val, orderId, orderDetail) => {
    const cDetails = state.cancelOrderDetails;
    cDetails.showConfirmation = val;
    cDetails.orderId = val?orderId:'';
    cDetails.orderDetail = val?orderDetail:'';
    cDetails.confirmed = false;
    return cDetails;

}
const preLoad = (type,cust, addr) => {
    if(!cust || !addr) {
        return {}
    }
    return {
        customerColor: cust['color'],
        customerHanger: cust['hanger'],
        customerLineid: cust['line_id'],
        customerName: cust['name'],
        customerPhone: cust['phone'],
        customerSendDay: cust['week_day'],
        customerEmail: cust['email'],
        deliveryService: cust['delivery_service'],
        addressNo: cust['address']?cust['address'][0]['no']:"",
        addressType: addr['type'],
        addressNameSelect1: addr['id'],
        addressNameSelect: addr['id'],
        addressAlley: addr['alley'],
        addressCountry: addr['country'],
        addressDistrict: addr['district'],
        addressName: addr['name'],
        addressPostalCode: addr['postal_code'],
        addressProvince: addr['province'],
        addressSubDistrict: addr['sub_district'],
      }
}
const  customerReducer = (state = initialState, { type, orders, preloadedValues, id ,data, customer, address, retailsMaster, packagesMaster, addonsMaster, packageMaster ,showConfirmation, cancelOrderId, orderDetail, _package, isForceReload }) => {
    switch (type) {
        case EDITED:
            return {...state, status:'EDITED', label:'แก้ไขข้อมูลลูกค้า',disabledForm: true, preloadedValues: preLoad(type,customer,address), isForceReload: isForceReload}
        case EDIT:
            return {...state, 
                status:'EDIT', 
                label:'แก้ไขข้อมูลลูกค้า',
                preloadedValues: preLoad(type,customer,address), 
                customer: customer, 
                address: address, 
                disabledForm: true,
                packagesMaster: packagesMaster,
                retailsMaster: retailsMaster,
                addonsMaster: addonsMaster,
                orders: orders
                
        }
        case EDITING:
            return {...state, status:'EDITING', label:'กำลังแก้ไขข้อมูลลูกค้า',disabledForm: false , preloadedValues: preloadedValues}
        case ADDING:
            return {...state, status:'ADDING', label:'เพิ่มลูกค้า',disabledForm: false , preloadedValues: preloadedValues}
        case ADD_PACKAGE:
            return {...state, newOrder: addPackage(state)}
        case ADD_ADDON:
            return {...state, newOrder: addAddon(state)}
        case OPEN_DIALOG:
            return {...state, newOrder: addPackage({newOrder:{packages:[],addons:[]}}), isOpen: true, btnRefEnable: true, packagesMaster: packagesMaster, addonsMaster: addonsMaster}
        case CLOSE_DIALOG:
            return {...state, isOpen: false}
        case ADD_ORDER:
            return {...state, newOrder: addPackage({newOrder:{packages:[],addons:[]}})}
        case DELETE_PACKAGE:
            return {...state, newOrder: deletePackage(state, id)}
        case DELETE_ADDON:
            return {...state, newOrder: deleteAddon(state, id)}
        case EVALUATE_ORDER:
            return {...state, newOrder: evaluateOrder(state, data)}
        case SHOW_CONFIRMATION:
            return {...state, cancelOrderDetails: setShowConfirmation(state, showConfirmation, cancelOrderId, orderDetail)}
        case CANCEL_ORDER:
            return {
                ...state, 
                cancelOrderDetails:{
                    showConfirmation: false,
                    confirmed: false,
                    orderId: '',
                    orderDetail: ''
                },
                orders : orders
                }
        case ADDED_ORDER:
            return {...state, 
                newOrer: {
                    price : 0,
                    packages : [],
                    addons : [],
                    status :''
                },
                isOpen: false,
                orders: orders
            }
        case ADD_USED:
            return {
                ...state, 
                recordUsed: 
                addUsed({recordUsed:{
                        useDetails:{},
                        groupId: packageMaster.pk,
                        packageId: packageMaster.sk,
                        quota_use:0,
                        actual_units:0,
                        status:'',
                        date_time:null
                    }
                })
            }
        case ADDED_USED:
            return {...state, 
                customer: customer, 
                newOrer: {
                    price : 0,
                    packages : [],
                    addons : [],
                    status :''
                },
                recordUsed: initialRecordUse
            }
        case EVALUATE_QUOTA:
            return {...state, recordUsed: evaluateQuota(state, data, _package)}
        case PREPARE_CHECKIN:
            return {...state, packagesMaster: packagesMaster}
        default:
            return {
                ...state, 
                // customer: customer, 
                // address: address,
                // packagesMaster: packagesMaster,
                // preloadedValues: preLoad(type,customer,address)
                
            }
    }
}
  
export default customerReducer;