const initialState = {
    sidebarShow: ''
  }
  
  

const changeStateReducer = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return {...state, ...rest }
        default:
            return {...state, ...rest }
    }
}
  
export default changeStateReducer;