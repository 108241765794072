export const ADDING = 'ADDING';
export const EDIT = 'EDIT';
export const EDITING = 'EDITING';
export const EDITED = 'EDITED';
export const ADD_PACKAGE = 'ADD_PACKAGE'
export const DELETE_PACKAGE = 'DELETE_PACKAGE'
export const EVALUATE_ORDER = 'EVALUATE_ORDER'
export const ADD_ORDER = 'ADD_ORDER'
export const SHOW_CONFIRMATION = 'SHOW_CONFIRMATION'
export const CANCEL_ORDER = 'CANCEL_ORDER'
export const ADDED_ORDER = 'ADDED_ORDER'
export const ADD_USED = 'ADD_USED'
export const EVALUATE_QUOTA = 'EVALUATE_QUOTA'
export const ADDED_USED = 'ADDED_USED'
export const OPEN_DIALOG = 'OPEN_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'
export const RECEIVED_SAVE = 'RECEIVED_SAVE'
export const ADD_ADDON = 'ADD_ADDON'
export const DELETE_ADDON = 'DELETE_ADDON'
export const PREPARE_CHECKIN = 'PREPARE_CHECKIN'