import { ADDING,EDIT,EDITED,EDITING } from './../actions/retailAction'

const initialState = {
    status: 'EDIT',
    label: 'แก้ไขข้อมูล',
    disabledForm: true,
    preloadedValues: null
  }
  
  

const retailReducer = (state = initialState, { type, preloadedValues }) => {
    switch (type) {
        case EDITED:
            return {...state, status:'EDITED', label:'แก้ไขข้อมูล',disabledForm: true, preloadedValues: preloadedValues}
        case EDIT:
            return {...state, status:'EDIT', label:'แก้ไขข้อมูล',disabledForm: true, preloadedValues: preloadedValues}
        case EDITING:
            return {...state, status:'EDITING', label:'กำลังแก้ไขข้อมูล',disabledForm: false , preloadedValues: preloadedValues}
        case ADDING:
            return {...state, status:'ADDING', label:'เพิ่ม',disabledForm: false , preloadedValues: preloadedValues}
        default:
            return {...state, preloadedValues: preloadedValues}
    }
}
  
export default retailReducer;