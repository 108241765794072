import { 
    RETAIL_CUSTOMER, 
    RETAIL_DEFAULT, 
    RETAIL_ADD_PACKAGE, 
    RETAIL_DELETE_RETAIL, 
    RETAIL_EVALUATE_RETAIL, 
    RETAIL_ADD_ORDER, 
    RETAIL_SHOW_CONFIRMATION, 
    RETAIL_CANCEL_ORDER, 
    RETAIL_ADDED_ORDER, 
    RETAIL_OPEN_DIALOG, 
    RETAIL_CLOSE_DIALOG 
} from './../actions/retailFormAction'
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    status : 'EDIT',
    isForceReload: false,
    label : 'แก้ไขข้อมูลลูกค้า',
    disabledForm : true,
    preloadedValues : 'null',
    newOrder : {
        price : 0,
        packages : [],
        status :''
    },
    recordUsed : {
        useDetails : {},
        date_time : null,
        quota_use :0,
        actual_units: 0,
        status:'',
        groupId:'',
        packageId:'',
        calculateResult:undefined
    },
    packagesMaster : null,
    customer : null,
    address : null,
    cancelOrderDetails: {
        showConfirmation: false,
        confirmed: false,
        orderId: '',
        orderDetail: ''
    },
    isOpen : false,
    btnRefEnable : true,
    receivedId : null
  }
const addUsed = (state) =>{
    let recordUsed = state.recordUsed;
    return recordUsed
}  
const addPackage = (state) =>{
    let newOrder = state.newOrder;
    const guid = uuidv4()
    newOrder.packages.push({id:guid,package:"",quantity:1,type:""})
    return newOrder
}  
const evaluateOrder = (state, data) =>{
    let newOrder = state.newOrder;
    newOrder.packages = data.packages
    newOrder.packages.forEach((pack)=>{
        pack['price'] = calculateItemPrice(pack,state.packagesMaster)
        pack['priceperunit'] = calculateUnitPrice(pack,state.packagesMaster)
    })
    newOrder.price =  newOrder.packages.reduce(function(prev, cur) {
        return prev + cur.price;
    }, 0);
    return newOrder 
}  


const evaluateQuota = (state, data, _package) =>{
    
    let recordUsed = state.recordUsed;
    recordUsed.status = data.status
    const calUse = getUseQuota(data,state)
    const calUnit = getUseUnit(data,state)
    let sumUsed = 0;
    if(_package.quota_used){
        Object.entries(_package.quota_used).map((item)=>{
            sumUsed += item[1].quota_used
        })
    }
    
    if(_package.quota >= (calUse + sumUsed)){
        recordUsed.useDetails = data.useDetails
        recordUsed.quota_use = calUse
        recordUsed.actual_units = calUnit
        recordUsed.calculateResult = undefined
    }else{
        recordUsed.calculateResult = 'ใช้เกินโควต้า'
    }
    return recordUsed
}  

const getUseQuota = (data, state) => {
    let packageGroup = state.packagesMaster.find(x=>x.sk === state.recordUsed.groupId)
    const quotaDetails = packageGroup.quota_counting ? Object.entries(packageGroup.quota_counting) : [];
    let countUsed = 0;
    quotaDetails.forEach(element => {
      
      if(typeof data.useDetails[element[0]] != 'undefined' ){
        countUsed +=  (element[1]  * data.useDetails[element[0]])
      }
      
    });
    return countUsed;

}

const getUseUnit = (data, state) => {
    let packageGroup = state.packagesMaster.find(x=>x.sk === state.recordUsed.groupId)
    const quotaDetails = packageGroup.quota_counting ? Object.entries(packageGroup.quota_counting) : [];
    let countUnit = 0;
    quotaDetails.forEach(element => {
      
      if(typeof data.useDetails[element[0]] != 'undefined' ){
        countUnit +=  (1  * data.useDetails[element[0]])
      }
      
    });
    return countUnit;

}



const calculateItemPrice = (_package, packagesMaster) => {
    let price = 0;
    if(!_package.subtype){
        return
    }
    const selectedPackage = packagesMaster.find(x=>x.id === _package.package)
    if(selectedPackage != null) {
        price += selectedPackage.costs[_package.subtype] * _package.quantity;
    }
    return price
    
}

const calculateUnitPrice = (_package, packagesMaster) => {
    let price = 0;
    if(!_package.subtype){
        return
    }
    const selectedPackage = packagesMaster.find(x=>x.id === _package.package)
    if(selectedPackage != null) {
        price += selectedPackage.costs[_package.subtype] ;
    }
    return price
    
}

const deletePackage = (state, id) =>{
    let newOrder = state.newOrder;
    const delItem = newOrder.packages.find(x=>x.id===id);
    const delIndex = newOrder.packages.indexOf(delItem)
    delete newOrder.packages[delIndex];
    newOrder.packages = newOrder.packages.filter(function (el) {
        return typeof el != 'undefined';
      });

    newOrder.price =  newOrder.packages.reduce(function(prev, cur) {
        return prev + cur.price;
    }, 0);
    return newOrder 
}  
const setShowConfirmation = (state, val, orderId, orderDetail) => {
    const cDetails = state.cancelOrderDetails;
    cDetails.showConfirmation = val;
    cDetails.orderId = val?orderId:'';
    cDetails.orderDetail = val?orderDetail:'';
    cDetails.confirmed = false;
    return cDetails;

}

const  retailFormReducer = (state = initialState, { type,receivedId, customerName, customerId, id ,data, customer, packagesMaster ,showConfirmation, cancelOrderId, orderDetail, _package }) => {
    switch (type) {
        case RETAIL_DEFAULT:
            return {...state, packagesMaster: packagesMaster}
        case RETAIL_CUSTOMER:
            return {...state, customerName: customerName, customerId: customerId, isOpen: true,receivedId: receivedId, newOrder: addPackage({newOrder:{packages:[]}})}
        case RETAIL_ADD_PACKAGE:
            return {...state, newOrder: addPackage(state)}
        case RETAIL_OPEN_DIALOG:
            return {...state, newOrder: addPackage({newOrder:{packages:[]}}), isOpen: true, btnRefEnable: true, packagesMaster: packagesMaster}
        case RETAIL_CLOSE_DIALOG:
            return {...state, 
                isOpen: false, 
                newOrder: addPackage({newOrder:{packages:[]}})
        }
        case RETAIL_ADD_ORDER:
            return {...state, newOrder: addPackage({newOrder:{packages:[]}})}
        case RETAIL_DELETE_RETAIL:
            return {...state, newOrder: deletePackage(state, id)}
        case RETAIL_EVALUATE_RETAIL:
            return {...state, newOrder: evaluateOrder(state, data)}
        case RETAIL_SHOW_CONFIRMATION:
            return {...state, cancelOrderDetails: setShowConfirmation(state, showConfirmation, cancelOrderId, orderDetail)}
        case RETAIL_CANCEL_ORDER:
            return {...state, customer: customer, cancelOrderDetails:{
                showConfirmation: false,
                confirmed: false,
                orderId: '',
                orderDetail: ''
            } }
        case RETAIL_ADDED_ORDER:
            return {...state, 
                customer: customer, 
                newOrer: {
                    price : 0,
                    packages : [],
                    status :''
                },
                customerId: null,
                customerName: '',
                isOpen: false,
                receivedId: null
            }
        
        default:
            return {
                ...state, 
                // customer: customer, 
                // address: address,
                // packagesMaster: packagesMaster,
                // preloadedValues: preLoad(type,customer,address)
                
            }
    }
}
  
export default retailFormReducer;