import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'
import Amplify from 'aws-amplify';
import { SignUp,  withAuthenticator } from 'aws-amplify-react';
import awsExports from "./aws-exports";
import '@aws-amplify/ui/dist/style.css';
Amplify.configure(awsExports);
React.icons = icons
const AppWithAuthenticator = withAuthenticator(App, {includeGreetings:false }, [

]);
ReactDOM.render(
  <Provider store={store}>
    <AppWithAuthenticator hide={[SignUp]} />,
  </Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
