/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:9ff57192-4022-4472-8fbc-3e5d7c14f648",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_HnQ6CyZkx",
    "aws_user_pools_web_client_id": "3l2esgh92apl6bn0gqnqieo6en",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "ap-southeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "customer-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "address-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "package-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "order-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "retail-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "appconfig-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "reception-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "addon-dev",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "orderapi",
            "endpoint": "https://i3j23xa0m7.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        },
        {
            "name": "imateapi",
            "endpoint": "https://w59258g7wc.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        }
    ]
};


export default awsmobile;
