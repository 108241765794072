import { ADDING,EDIT,EDITED,EDITING } from './../actions/packageAction'

const initialState = {
    status: 'EDIT',
    label: 'แก้ไขข้อมูลแพ็คเกจ',
    disabledForm: true,
    preloadedValues: null
  }
  
  

const packageReducer = (state = initialState, { type, preloadedValues }) => {
    switch (type) {
        case EDITED:
            return {...state, status:'EDITED', label:'แก้ไขข้อมูลแพ็คเกจ',disabledForm: true, preloadedValues: preloadedValues}
        case EDIT:
            return {...state, status:'EDIT', label:'แก้ไขข้อมูลแพ็คเกจ',disabledForm: true, preloadedValues: preloadedValues}
        case EDITING:
            return {...state, status:'EDITING', label:'กำลังแก้ไขข้อมูลแพ็คเกจ',disabledForm: false , preloadedValues: preloadedValues}
        case ADDING:
            return {...state, status:'ADDING', label:'เพิ่มแพ็คเกจ',disabledForm: false , preloadedValues: preloadedValues}
        default:
            return {...state, preloadedValues: preloadedValues}
    }
}
  
export default packageReducer;