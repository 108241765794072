import { createStore, combineReducers, applyMiddleware  } from 'redux'
import postReducer from './reducers/postReducer';
import sidebarReducer from './reducers/sidebarReducer';
import customerReducer from './reducers/customerReducer';
import packageReducer from './reducers/packageReducer';
import orderReducer from './reducers/orderReducer';
import retailReducer from './reducers/retailReducer';
import retailFormReducer from './reducers/retailFormReducer';
function logger({ getState }) {
  return next => action => {
    //console.log('will dispatch', action)

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action)

    //console.log('state after dispatch', getState())

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
  }
}
const reducer = combineReducers({
  sidebarReducer,
  postReducer,
  customerReducer,
  packageReducer,
  orderReducer,
  retailReducer,
  retailFormReducer
})


const store = createStore(reducer, applyMiddleware(logger));
export default store