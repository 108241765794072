export const RETAIL_ADD_PACKAGE = 'ADD_PACKAGE'
export const RETAIL_DELETE_RETAIL = 'DELETE_RETAIL'
export const RETAIL_EVALUATE_RETAIL = 'EVALUATE_RETAIL'
export const RETAIL_ADD_ORDER = 'ADD_ORDER'
export const RETAIL_SHOW_CONFIRMATION = 'SHOW_CONFIRMATION'
export const RETAIL_CANCEL_ORDER = 'CANCEL_ORDER'
export const RETAIL_ADDED_ORDER = 'ADDED_ORDER'
export const RETAIL_EVALUATE_QUOTA = 'EVALUATE_QUOTA'
export const RETAIL_OPEN_DIALOG = 'OPEN_DIALOG'
export const RETAIL_CLOSE_DIALOG = 'CLOSE_DIALOG'
export const RETAIL_DEFAULT = 'DEFAULT'
export const RETAIL_CUSTOMER = 'CUSTOMER'

