import { ADD_PACKAGE,EDIT } from './../actions/orderAction'
import { v4 as uuidv4 } from 'uuid';
const initialState = {
    status: 'EDIT',
    selectedPackages:[{id:uuidv4(),package:"",quantity:1}]
  }
  
  const addPackage = () =>{
    return this.selectedPackages
}  

const orderReducer = (state = initialState, { type, selectedPackages }) => {
    switch (type) {
        case ADD_PACKAGE:
            return {...state, status:'EDIT', selectedPackages: addPackage}
        case EDIT:
            return {...state, status:'EDIT', selectedPackages: selectedPackages}
        default :
            return {...state}
    }
}
  
export default orderReducer;